import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal", "backdrop", 'form', 'text', 'approve'];

  initialize = () => {
    this.modalTemplateText = this.element.getAttribute("data-modal-template-text");
    this.observeIfBackdropDisplayed();
  }

  observeIfBackdropDisplayed() {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        if (this.backdropTarget.style.display === 'block') this.show();
      });
    });

    observer.observe(this.backdropTarget, {
      attributes: true,
      attributeFilter: ['style']
    });
  }

  show() {
    this.textTarget.innerText = this.getModalText();
    this.modalTarget.style.display = "block";
    this.modalTarget.style.paddingRight = "15px";
    setTimeout(() => this.modalTarget.className="modal fade show", 150);
  }

  close() {
    this.modalTarget.style.display = "none";
    this.modalTarget.className="modal fade";
    this.backdropTarget.style.display = "none";
  }

  submitForm = () => {
    const submit = this.formTarget.querySelector('input[type=submit]');
    submit.click();
  }

  getModalText = () => this.modalTemplateText.replace("{ROT}", this.getROT());

  getROT() {
    if (this.formTarget.elements['project[ROT]'] && this.formTarget.elements['project[ROT]'].value)
      return this.formTarget.elements['project[ROT]'].value
    return this.formTarget.elements['agreement_addendum[ROT]'].value
  }

  approve = () => this.approveTarget.click();
}
