import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["renderArea", "template"];

  add_group(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().valueOf()
    );
    this.renderAreaTarget.insertAdjacentHTML("beforeend", content);
  }

  remove_group(event) {
    event.preventDefault();
    const item = event.target.closest("div[data-field-group='true']");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.classList.add("d-none");
  }
}
