import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item", "chevron"];

  toggle(e) {
    e.preventDefault();
    if (this.hasItemTarget) {
      if (this.itemTarget.classList.contains("d-none"))
        this.chevronTarget.classList.replace(
          "icons__chevron-right",
          "icons__chevron-down"
        );
      else
        this.chevronTarget.classList.replace(
          "icons__chevron-down",
          "icons__chevron-right"
        );
      this.itemTarget.classList.toggle("d-none");
    }
  }
}
