import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "email",
    "mobilePhone",
    "phone",
    "civicReg",
    "firstName",
    "lastName",
  ];
  connect() {
    if (this.civicRegTarget.value) {
      //if user is found by target value, set field values and disable them
      //else, the user is new, do not reset the values since we're still filling in the form (after validation errors)
      let civicRegistrationNumber = this.trim_civic_registration_number(
        this.civicRegTarget.value
      );
      this.get_user_data(civicRegistrationNumber, false);
    } else {
      //if no value in target, just disable all fields until a correct personnummer is inputted
      this.disableInputs();
    }
  }

  disableInputs() {
    !this.emailTarget.readOnly ? (this.emailTarget.readOnly = true) : null;
    !this.mobilePhoneTarget.readOnly
      ? (this.mobilePhoneTarget.readOnly = true)
      : null;
    !this.firstNameTarget.readOnly
      ? (this.firstNameTarget.readOnly = true)
      : null;
    !this.lastNameTarget.readOnly
      ? (this.lastNameTarget.readOnly = true)
      : null;
  }

  enableInputs() {
    this.emailTarget.readOnly ? (this.emailTarget.readOnly = false) : null;
    this.mobilePhoneTarget.readOnly
      ? (this.mobilePhoneTarget.readOnly = false)
      : null;
    this.firstNameTarget.readOnly
      ? (this.firstNameTarget.readOnly = false)
      : null;
    this.lastNameTarget.readOnly
      ? (this.lastNameTarget.readOnly = false)
      : null;
  }

  resetInputs() {
    this.emailTarget.value = "";
    this.mobilePhoneTarget.value = "";
    this.firstNameTarget.value = "";
    this.lastNameTarget.value = "";
  }

  handleCivicRegChange() {
    let civicRegistrationNumber = this.trim_civic_registration_number(
      this.civicRegTarget.value
    );
    if (civicRegistrationNumber.length === 12) {
      this.get_user_data(civicRegistrationNumber, true);
    } else {
      this.disableInputs();
      this.resetInputs();
    }
  }

  //numbers with format yyyymmdd-xxxx should  be allowed in client but not in backend
  trim_civic_registration_number(targetValue) {
    let civicRegistrationNumber = targetValue.replace("-", "");
    return civicRegistrationNumber;
  }

  get_user_data(civic_reg, reset_inputs) {
    Rails.ajax({
      url: "/api/user_data",
      type: "get",
      data: `civic_reg=${civic_reg.toString()}`,
      success: (data) => {
        console.log(data);
        if (data.email && data.mobile_phone) {
          this.setValues(data);
          this.disableInputs();
        } else {
          //no user was found
          this.enableInputs();
          if (reset_inputs) {
            this.resetInputs();
          }
        }
      },
      error: () => {
        this.enableInputs();
        this.resetInputs();
      },
    });
  }

  setValues(data) {
    this.emailTarget.value = data.email;
    this.mobilePhoneTarget.value = data.mobile_phone;
    this.firstNameTarget.value = data.first_name;
    this.lastNameTarget.value = data.last_name;
  }
}
