import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["commentBlock", "respondToCommentBlock"];

  toggle(e) {
    e.preventDefault();
    if (this.hasRespondToCommentBlockTarget && this.hasCommentBlockTarget) {
      this.respondToCommentBlockTarget.classList.toggle("d-none");
      this.commentBlockTarget.classList.toggle("d-none");
    }
  }
}
