import { Controller } from "stimulus";

let checkedProjectFiles = [];

export default class extends Controller {
  static targets = ["submit", "addDocumentation"];

  isFormValid() {
    if (checkedProjectFiles.length > 0) {
      return this.submitTarget.removeAttribute("disabled");
    }
    this.submitTarget.setAttribute("disabled", "");
  }

  handleCheckBox(e) {
    if (e.target.checked) {
      checkedProjectFiles.push(e.target.value);
    } else {
      let index = checkedProjectFiles.indexOf(e.target.value);
      if (index !== -1) checkedProjectFiles.splice(index, 1);
    }

    this.isFormValid();
  }

  toggle(e) {
    e.preventDefault();
    if (this.hasAddDocumentationTarget) {
      this.addDocumentationTarget.classList.toggle("d-none");
    }
  }
}
