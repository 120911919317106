import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["wrapper", "label"];
  wrapperInputs = [];
  resetInputs;

  connect() {
    const input = this.labelTarget.querySelector("input[type='checkbox']");
    input.addEventListener("change", () => this.toggle());

    this.wrapperInputs = this.wrapperTarget.querySelectorAll("input, textarea");
    this.resetInputs =
      this.wrapperTarget.dataset.resetInputs == "true" ||
      this.wrapperTarget.dataset.resetInputs == "";
  }

  toggle() {
    const activeClass = "ireno-animated-checkbox__wrapper--active";
    this.wrapperTarget.classList.toggle(activeClass);

    if (this.resetInputs) {
      const isActive = this.wrapperTarget.classList.contains(activeClass);
      if (!isActive) {
        this.wrapperInputs.forEach((input) => (input.value = ""));
      }
    }
  }
}
