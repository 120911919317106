import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["add_item", "template", "approveBlock", "refuteBlock"];

  add_file(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().valueOf()
    );
    this.add_itemTarget.insertAdjacentHTML("beforebegin", content);
  }

  remove_file(event) {
    event.preventDefault();
    const item = event.target.closest("div[data-addendum-fields='true']");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = "none";
  }

  toggle(e) {
    e.preventDefault();
    if (this.hasApproveBlockTarget && this.hasRefuteBlockTarget) {
      this.refuteBlockTarget.classList.toggle("d-none");
      this.approveBlockTarget.classList.toggle("d-none");
    }
  }
}
