import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fileInput", "message", "fileContainer", "noFileContainer"];

  connect() {
    this.fileInputTarget.onchange = (e) => this.handleFile(e);
    const hasFileAttached = this.fileContainerTarget.dataset.file == "true";
    if (hasFileAttached) this.fileContainerTarget.style.display = "block";
    else this.noFileContainerTarget.style.display = "block";
  }

  handleFile(e) {
    this.messageTarget.textContent = e.target.files[0].name;
  }

  clickUpload(e) {
    e.preventDefault();
    this.fileInputTarget.click();
  }

  openNoFileContainer(e) {
    e.preventDefault();
    this.fileContainerTarget.style.display = "none";
    this.noFileContainerTarget.style.display = "block";
  }
}
