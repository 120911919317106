import { Controller } from "stimulus";
import AutoNumeric from "autonumeric";

export default class extends Controller {
  connect() {
    new AutoNumeric(this.element, {
      decimalCharacter: ",",
      digitGroupSeparator: " ",
    });
  }
}
