import { Controller } from "stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  static targets = ["container", "content"];
  connect() {
    tippy(this.containerTarget, {
      content: this.contentTarget.innerHTML,
      allowHTML: true,
      placement: "bottom",
    });
  }
}
