import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["approveBlock", "refuteBlock"];

  toggle(e) {
    e.preventDefault();
    if (this.hasApproveBlockTarget && this.hasRefuteBlockTarget) {
      this.refuteBlockTarget.classList.toggle("d-none");
      this.approveBlockTarget.classList.toggle("d-none");
    }
  }
}
