import { Controller } from "stimulus";
import AutoNumeric from "autonumeric";

export default class extends Controller {
  static targets = [
    "paymentPlan",
    "addItem",
    "template",
    "usePaymentPlan",
    "totalSumCents",
    "finalInspectionSumCents",
    "newSteps",
  ];

  connect() {
    if (this.usePaymentPlanTarget.checked) this.enablePaymentPlan();
  }

  togglePaymentPlan() {
    if (this.usePaymentPlanTarget.checked) {
      this.unsetAllPaymentPlanStepsDestroy();
      this.enablePaymentPlan();
      this.calculateFinalInspectionSumCents();
    } else {
      this.setAllPaymentPlanStepsDestroy();
      this.resetFinalInspectionSumCents();
    }
  }

  enablePaymentPlan() {
    this.unsetAllPaymentPlanStepsDestroy();
  }

  add_payment_plan_step(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().valueOf()
    );
    this.newStepsTarget.insertAdjacentHTML("beforeend", content);
  }

  remove_payment_plan_step(event) {
    event.preventDefault();
    const el = event.target.closest(
      "fieldset[data-payment-plan-step-fields='true']"
    );
    this.set_destroy_payment_plan_step(el);
  }

  set_destroy_payment_plan_step(el) {
    el.querySelector("input[name*='_destroy']").value = 1;
    el.style.display = "none";
  }

  unset_destroy_payment_plan_step(el) {
    el.querySelector("input[name*='_destroy']").value = 0;
    el.style.display = "block";
  }

  setAllPaymentPlanStepsDestroy() {
    this.paymentPlanTarget
      .querySelectorAll("fieldset[data-payment-plan-step-fields='true']")
      .forEach((el) => this.set_destroy_payment_plan_step(el));
  }

  unsetAllPaymentPlanStepsDestroy() {
    this.paymentPlanTarget
      .querySelectorAll("fieldset[data-payment-plan-step-fields='true']")
      .forEach((el) => this.unset_destroy_payment_plan_step(el));
  }

  calculateFinalInspectionSumCents() {
    //Since input fields are of type text.
    //And currency can be formated using "," instead of "."
    //we need to make sure that we are calculating using a number

    const totalSum = Number.parseInt(
      this.totalSumCentsTarget.value.replace(/\s/g, "")
    );
    const inspectionSum = totalSum * 0.1;
    if (Number.isNaN(inspectionSum) || inspectionSum === null) {
      this.finalInspectionSumCentsTarget.value = null;
    } else {
      this.finalInspectionSumCentsTarget.value = AutoNumeric.format(
        inspectionSum,
        { decimalCharacter: ",", digitGroupSeparator: " " }
      );
    }
  }

  resetFinalInspectionSumCents() {
    this.finalInspectionSumCentsTarget.value = 0;
  }
}
