import { Controller } from "stimulus";

// Toggles display on two targets

export default class extends Controller {
  static targets = ["primaryBlock", "secondaryBlock"];
  toggle(e) {
    e.preventDefault();
    if (this.hasPrimaryBlockTarget && this.hasSecondaryBlockTarget) {
      this.primaryBlockTarget.classList.toggle("d-none");
      this.secondaryBlockTarget.classList.toggle("d-none");
    }
  }
}
